import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/tpa-settings';
import { initLoyaltyCouponNames } from '@wix/loyalty-coupon-names';
import { loyaltyMyRewardsOpen } from '@wix/bi-logger-loyalty-uou/v2';
import {
  createMembersAreaWidgetPluginService,
  createWidgetPluginExports,
} from '@wix/members-area-widget-plugin-lib/viewer';

import { RewardOrigin } from '../../types/domain';
import { SettingsEvents, RewardsTabState } from '../../types/settings';
import {
  compareRewardsFn,
  createRewardDescriptionBuilder,
  toSimpleAccount,
  toSimpleRewards,
  createClaimedCouponTitleBuilder,
  getAppInstallStatus,
  isRewardAvailable,
  getDemoContent,
  loadData,
} from '../../utils';
import { createStore, getActionHandlers } from './Widget/store';
import type { ControllerProps } from './Widget';
import { RequestStatus } from '../../types/store';
import { rewardsSlice } from './Widget/store/slices';

const createController: CreateControllerFn = async ({
  controllerConfig: { config, setProps, wixCodeApi },
  flowAPI,
}) => {
  const { isEditor, isPreview, isViewer } = flowAPI.environment;
  const locale = wixCodeApi.site.regionalSettings ?? 'en-US';
  const currency = wixCodeApi.site.currency ?? 'USD';

  const widgetPluginService = createMembersAreaWidgetPluginService();
  const componentEventHandler = createEventHandler<SettingsEvents>(config.publicData.COMPONENT || {});
  const setWidgetProps = (props: Partial<ControllerProps>) => {
    setProps(props);
  };

  componentEventHandler.on('rewardsTabState', (rewardsTabState: RewardsTabState) =>
    setWidgetProps({
      rewardsTabState,
    }),
  );
  componentEventHandler.onReset(() =>
    setWidgetProps({
      rewardsTabState: RewardsTabState.Default,
    }),
  );

  // See: https://wix.slack.com/archives/C01480U2SAF/p1643623573887269
  setProps({ fitToContentHeight: true });

  const pageReady = async () => {
    try {
      const [appInstallStatus] = await Promise.all([
        getAppInstallStatus(flowAPI.httpClient),
        initLoyaltyCouponNames(flowAPI.translations.i18n),
      ]);

      const useDemoContent = isEditor || isPreview;
      const {
        loyaltyProgram,
        account,
        rewards,
        templateCoupons,
        claimedCoupons,
        referralCouponRewards,
        couponEntityNames,
        tiersProgram,
      } = useDemoContent
        ? await getDemoContent({ flowAPI, appInstallStatus })
        : await loadData({ flowAPI, appInstallStatus });

      const store = createStore(
        {
          flowAPI,
          wixCodeApi,
        },
        {
          accountConfig: {
            fetchAccountStatus: RequestStatus.IDLE,
            account: toSimpleAccount(account),
            currency,
          },
          couponsConfig: {
            redeemCouponStatus: RequestStatus.IDLE,
            claimedCoupons,
            templateCoupons,
          },
          rewardsConfig: {
            simpleRewards: [],
            rawRewards: rewards,
            redeemRewardStatus: RequestStatus.IDLE,
          },
          loyaltyProgramConfig: {
            program: loyaltyProgram ?? {},
          },
          tiersProgramConfig: {
            programSettings: tiersProgram.programSettings ?? {},
            tiers: tiersProgram.tiers ?? [],
          },
          appInstallStatusConfig: appInstallStatus,
        },
      );

      const simpleRewards = toSimpleRewards({
        flowAPI,
        rewards,
        coupons: templateCoupons,
        claimedCoupons,
        referralCouponRewards,
        couponEntityNames,
        buildRewardDescription: createRewardDescriptionBuilder(loyaltyProgram!, flowAPI, locale, currency),
        buildClaimedCouponTitle: createClaimedCouponTitleBuilder(flowAPI, locale, currency),
      });

      flowAPI.bi?.updateDefaults({
        totalPoints: account.points?.balance,
        totalRewards: simpleRewards.filter((r) => r.rewardOrigin === RewardOrigin.USER).length,
        availableRewards: simpleRewards
          .filter((r) => r.rewardOrigin === RewardOrigin.USER)
          .filter((r) =>
            isRewardAvailable({ requiredPoints: r.requiredPoints, pointsBalance: account.points?.balance }),
          )
          .map((r) => r.id)
          .join(','),
        unavailableRewards: simpleRewards
          .filter((r) => r.rewardOrigin === RewardOrigin.USER)
          .filter(
            (r) => !isRewardAvailable({ requiredPoints: r.requiredPoints, pointsBalance: account.points?.balance }),
          )
          .map((r) => r.id)
          .join(','),
      });

      const sortedRewards = simpleRewards
        .slice()
        .sort((first, second) => compareRewardsFn(first, second, account.points?.balance));

      store.dispatch(rewardsSlice.actions.setSimpleRewards(sortedRewards));

      setWidgetProps({
        ...store.getState(),
        ...getActionHandlers(store),
      });

      store.subscribe(() =>
        setWidgetProps({
          ...store.getState(),
        }),
      );
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        flowAPI.reportError(e);
      }
      setProps({
        hasError: true,
      });
    }

    if (isViewer) {
      flowAPI.bi?.report(loyaltyMyRewardsOpen({}));
    }
  };

  return {
    async pageReady() {
      const isRendered = widgetPluginService.getIsRendered();
      if (!isRendered) {
        return;
      }

      await pageReady();
    },
    updateConfig(_$w, newConfig) {
      componentEventHandler.notify(newConfig.publicData.COMPONENT || {});
    },
    exports: () => createWidgetPluginExports(widgetPluginService, pageReady),
  };
};

export default createController;
