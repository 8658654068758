import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { Reward } from '@wix/ambassador-loyalty-v1-reward/types';
import { loyaltyUouApplyRewardClick } from '@wix/bi-logger-loyalty-uou/v2';
import {
  WIX_RESTAURANTS_ORDERS as APP_ID_RESTAURANTS_ORDERS_OLD,
  WIX_RESTAURANTS_ORDERS_NEW as APP_ID_RESTAURANTS_ORDERS_NEW,
} from '@wix/app-definition-ids';

const WIDGET_ID_RESTAURANTS_ORDERS_OLD = '96254d42-7323-40cb-a7cb-b7c242019728';
const WIDGET_ID_RESTAURANTS_ORDERS_NEW = '5077609c-e47c-4da6-8147-008d243e4499';

import { SimpleReward } from '../../../../../../types/domain';
import { RequestStatus, ThunkApiConfig } from '../../../../../../types/store';
import { AppInstallStatus } from '../../../../../../utils';

export interface RewardsConfigProps {
  redeemRewardStatus: RequestStatus;
  rawRewards: Reward[];
  simpleRewards: SimpleReward[];
}

const initialState: RewardsConfigProps = {
  redeemRewardStatus: RequestStatus.IDLE,
  rawRewards: [],
  simpleRewards: [],
};

const navigateToRestaurantMenuPage = async (
  flowAPI: ControllerFlowAPI,
  { isNewRestaurantAppInstalled }: AppInstallStatus,
) => {
  const { wixCodeApi } = flowAPI.controllerConfig;

  const appDefinitionId = isNewRestaurantAppInstalled ? APP_ID_RESTAURANTS_ORDERS_NEW : APP_ID_RESTAURANTS_ORDERS_OLD;
  const sectionId = isNewRestaurantAppInstalled ? WIDGET_ID_RESTAURANTS_ORDERS_NEW : WIDGET_ID_RESTAURANTS_ORDERS_OLD;
  const url = await wixCodeApi.site.getSectionUrl({ appDefinitionId, sectionId });

  if (url.relativeUrl) {
    wixCodeApi.location.to?.(url.relativeUrl);
  } else {
    throw new Error('Could not find restaurants menu page');
  }
};

const redeemReward = createAsyncThunk<void, SimpleReward, ThunkApiConfig>(
  'rewards/redeemReward',
  async (simpleReward, { extra, getState, rejectWithValue }) => {
    const {
      rewardsConfig: { rawRewards },
      accountConfig: { account },
      appInstallStatusConfig,
    } = getState();
    const { flowAPI, wixCodeApi } = extra;
    const { isViewer } = flowAPI.environment;

    const reward = rawRewards?.find(({ id }) => id === simpleReward.id);
    if (!reward) {
      return;
    }

    if (isViewer && reward.id) {
      const params = new URLSearchParams(wixCodeApi.location.url);
      flowAPI.bi?.report(
        loyaltyUouApplyRewardClick({
          rewardId: reward.id,
          rewardName: reward.name,
          rewardType: reward.type,
          totalPoints: account.pointsBalance!,
          referralInfo: params.has('referralInfo') ? params.get('referralInfo') ?? '' : '',
        }),
      );
    }

    try {
      await navigateToRestaurantMenuPage(flowAPI, appInstallStatusConfig);
    } catch (error) {
      if (error instanceof Error) {
        flowAPI.reportError(error);
      }
      rejectWithValue(error);
    }
  },
);

export const rewardsSlice = createSlice({
  name: 'rewards',
  initialState,
  reducers: {
    resetRedeemRewardStatus: (state) => {
      state.redeemRewardStatus = RequestStatus.IDLE;
    },
    setSimpleRewards: (state, action: PayloadAction<SimpleReward[]>) => {
      state.simpleRewards = action.payload;
    },
    addReward: (state, action: PayloadAction<SimpleReward>) => {
      state.simpleRewards.unshift(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(redeemReward.fulfilled, (state) => {
        state.redeemRewardStatus = RequestStatus.SUCCESS;
      })
      .addCase(redeemReward.pending, (state) => {
        state.redeemRewardStatus = RequestStatus.LOADING;
      })
      .addCase(redeemReward.rejected, (state) => {
        state.redeemRewardStatus = RequestStatus.FAILURE;
      });
  },
});

export const rewardsThunk = { redeemReward };
